import { Conf } from '../glaze/conf';
import { model_plot } from '../glaze/model_plot';
import { rpc } from '../common/crystal_api/rpc';
import { get_selected_db_id } from '../common/hash';
import { show_ephemeral_toast, show_sticky_toast } from '../common/toast';
import { empty_element, show_element_with_opacity_transition } from '../common/ui';
import { get_vars } from '../common/url';
import { create_menu_report } from '../common/report_menu';
import { create_modal } from '../common/html_elements';
import { capitalize_string } from '../common/utils';

globalThis.search_from_report_with_parameter = (parameter, value) => {
  const db = get_selected_db_id();
  const uid = get_vars()['uid'];
  const hash = `${db}?uid=${uid}&${parameter}=${value}&period=All&orphans=include&search=true`;
  globalThis.report_search_doc = false;
  location.href = hash;
};

function create_link_and_download(doc_id) {
  const error_is_managed_by_rpc = () => {};
  rpc.link_create({ doc_id: doc_id, expiry: 1 }).then(download_from_response, error_is_managed_by_rpc);
}

function download_from_response(response) {
  if (response.error) {
    show_sticky_toast(`${response.error}`, 'Error');
    return;
  }
  location.href = response.url;
}

export class ReportConf extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_report_view = true;
    this.plots_configurations = {};
    this.ignore_options = ['alien', 'nSamples', 'f_hash', 'curveHash', 'related'];
  }

  get_template_file() {
    return 'report_view.handlebars';
  }

  get_document_desc() {
    this._check_section();
    let default_section_name = globalThis.active_document_tab || 'main';
    let doc_active_section = '{';
    const doc_section_list = this.sorted_sections.map((sec_name) => `${sec_name}: ${sec_name === default_section_name}`);
    doc_active_section += doc_section_list.join(',');
    doc_active_section += '}';
    const desc = {
      report_id: this.currents['testid'],
      report_uid: this.uid,
      report_name: this.currents['name'],
      report_instrument: this.currents['instrument'].toUpperCase(),
      object_id: this.objid,
      x_data: doc_active_section,
      report_elapsed: this.currents['elapsed'] * 60,
      show_images_div: this.currents.instrument === 'hsm' && this.currents.nSamples && this.currents.nSamples > 0,
      report_currents: JSON.stringify(this.currents),
    };
    return desc;
  }

  get_view_parent() {
    return 'document_container';
  }

  get_ignore_options() {
    return super.get_ignore_options().concat(this.ignore_options);
  }

  document_view_ready() {
    if (this.is_report_view) this.show_action_buttons();
    model_plot(this);
  }

  show_update_alert() {
    return this.currents['f_stat']['st_mtime'] > this.currents['modified'];
  }

  view() {
    super.view();
    if (this.styles['sample0_d'] && this.currents['sample0_initialDimension']) {
      this.styles['sample0_d']['initialDimension'] = this.currents['sample0_initialDimension'];
    }
    show_element_with_opacity_transition('document_container');
  }

  show_action_buttons() {
    create_menu_report(`${this.uid}_container`, this);
  }

  show_quartz_icon() {
    return ['ReportVertical', 'ReportHorizontal'].includes(this.class_name) && this.currents.maxTemp >= 600;
  }

  push_report_state_to_local_storage(conf_type) {
    return this.push_to_local_storage(conf_type);
  }

  push_to_local_storage(conf_type) {
    const keys_to_match = ['name', '_id'];
    const report = JSON.stringify(
      Object.keys(this.currents)
        .filter((k) => keys_to_match.some((key_to_match) => k === key_to_match))
        .reduce((acc, key) => {
          acc[key] = this.currents[key];
          return acc;
        }, {})
    );

    set_cookie(`new_conf_initial_state_Model${conf_type}`, report);
  }

  update_shape_table_option(name, temp, new_row) {
    const shape_table_option = this.options['shapes'];
    const shapes_order = ['Sintering', 'Softening', 'Sphere', 'HalfSphere', 'Melting'];
    if (new_row) {
      shape_table_option['new_current'].splice(shapes_order.indexOf(name), 0, [name, temp]);
    } else {
      const row = shape_table_option['new_current'].find((row) => row[0] === name);
      if (row) {
        row[1] = parseFloat(temp);
      }
    }
    shape_table_option.apply_changes();
  }

  set_shape(name, value, key) {
    const new_shape_current = { temp: parseFloat(value['temp']), value: 0, time: parseFloat(value['time']) };
    this.options[key] = new_shape_current;
    const smp_idx = key.split('_')[0];
    const m3_key = key.split('_').join('_m3_');
    const exlude_keys = ['current', 'priority'];
    const new_shape_style = {};
    Object.keys(this.options[m3_key]['style']).forEach((k) => {
      if (!exlude_keys.includes(k)) new_shape_style[k] = this.options[m3_key]['style'][k];
    });
    new_shape_style['handle'] = `${key}`;
    new_shape_style['kid'] = `/hsm/${smp_idx}/${name}`;
    this.options[key] = new_shape_style;
    this.styles[key] = new_shape_style;
    this.update_shape_table_option(name, value['temp'], true);
    this.log.add_style(new_shape_style);
    this.log.add_value(new_shape_current, key);
  }

  update_shape(name, value, key) {
    const new_shape = { temp: parseFloat(value['temp']), value: value['value'], time: parseFloat(value['time']) };
    const shape_option = this.options[key];
    this.log.add_value(new_shape, key);
    shape_option.apply_changes();
    this.update_shape_table_option(name, value['temp'], false);
  }

  restore_shapes() {
    empty_element(`${this.currents.testid}_shapes`);
    lambda_shapes(this.currents, false);
  }

  restore_originals() {
    super.restore_originals();
    this.restore_shapes();
  }

  share_report(uid) {
    console.log('Share report', uid);
    const share_report_template = require('../view/document/share_report_dialog_content.handlebars');
    const share_report_html = share_report_template();
    create_modal({ id: 'share_report', title: _('Share report'), content: share_report_html }, [
      {
        button_text: 'Confirm',
        button_id: 'share_report_button',
        func: () => this.report_to_customer(uid),
        delete_modal: true,
      },
    ]);
    return;
  }

  report_to_customer(uid) {
    const receiver_cid = document.getElementById('share_receiver_cid').value;
    rpc.share({ uid: uid, destination: receiver_cid }).then((resp) => {
      show_ephemeral_toast('Document created', 'Info');
    });
  }
}

globalThis.create_link_and_download = create_link_and_download;
